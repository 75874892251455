@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Cairo", sans-serif;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

.section-3,
.contact {
  background-color: #5adbd0;
  background-image: linear-gradient(180deg, #5fddd1, transparent);
}

.underLine {
  background-image: linear-gradient(90deg, #222928, #5adbd0);
}

@media only screen and (max-width: 1024px) {
  .section-3,
  .contact {
    background: none;
    background-image: none;
  }
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f4b5b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
